/* 枠ID
 * 対象枠のクエリパラメータとして付与し、広告枠の計測に利用する
 * ex. https://ecnavi.jp/ad/xxxxxx/show?ecnframe=1001
 *
 * /sites/common/src/Ecnavi/Common/Constants/EcnFrames.php のjs版
 */

export const EcnFrames = {
  /** スライドバナー */
  TOP_SLIDE_BANNER: 1001,

  /** おすすめアンケート */
  OSUSUME_ENQUETE: 1025,

  /** タイムセール */
  TIME_SALE: 1002,

  /** 目玉ショップ */
  MEDAMA_SHOP: 1003,

  /** ナビックの新着 */
  NAVIC_NEW: 1004,

  /** イチオシ */
  ICHIOSHI: 1005,

  /** 新着サービス */
  NEW_SERVICE: 1006,

  /** 特集・キャンペーン */
  TOKUSYU_CAMPAIGN: 1007,

  /** 注目ショップ */
  FEATURED_SHOP: 1008,

  /** 定番ショップ */
  TEIBAN_SHOP: 1009,

  /** ピックアップ */
  PICKUP: 1010,

  /** ECナビのキャンペーン */
  ECNAVI_CAMPAIGN: 1026,

  /** 注目サービス */
  FEATURED_SERVICE: 1011,

  /** PCガラポン */
  PC_GARAPON: 1012,

  /** SPガラポン */
  SP_GARAPON: 1013,

  /** たぬきときつねでドロン */
  DORON: 1014,

  /** クリック募金 */
  CLICK_BOKIN: 1027,

  /** 釣りパンダ */
  FISHING_PANDA: 1015,

  /** 珍獣トライアスロン */
  CHINJU_TRIATHLON: 1016,

  /** AR */
  AR: 1017,

  /** キャンペーン一覧 */
  CAMPAIGN: 1028,

  /** ARメール */
  AR_MAIL: 1018,

  /** おすすめショップ */
  OSUSUME_SHOP: 1019,

  /** クリックくじ */
  CLICK_LOT: 1029,

  /** スマホポイントマガジン */
  SP_POINT_MAGAZINE: 1020,

  /** ポイントマガジン */
  PC_POINT_MAGAZINE: 1021,

  /** お買い物で貯めるページ（仮） */
  SHOPPING: 1024,

  /** AR回答後→LP */
  AR_CLICK_TYPE1: 1030,

  AR_CLICK_TYPE2: 1031,

  AR_CLICK_TYPE3: 1032,

  /** アンケートアプリのサービスタブ検索一覧 */
  ENQUETE_APP_SEARCH: 1036,

  /** アンケートアプリのサービスタブカテゴリー */
  ENQUETE_APP_CATEGORY: 1037,

  /** おすすめサービス */
  OSUSUME_SERVICE: 1039,

  /** お気に入り */
  FAVORITE: 1040,

  /** ナビック新着 */
  NAVIC_INFO: 1041,

  /** ポイントUPチャレンジ */
  POINT_UP_CHALLENGE: 1047,

  /** ショップ&サービス利用履歴 */
  AFFILIATE_HISTORY: 1052,

  /** 案件詳細 Twitterシェア */
  TWITTER: 1054,

  /** 案件詳細ページ LINEシェア */
  LINE: 1056,

  /** 案件詳細シェア登録完了経由 */
  SIGNUP_CREATED_VIA_AD_SHARE: 1057,

  /** 案件詳細ページ リンクコピーシェア */
  SHARE_LINK: 1058,

  /** ECナビふるさと納税比較 */
  HOMETOWN_TAX: 1059,

  /** アンケートアプリトップバナー経由 */
  ENQUETE_APP: 1061,

  /** 超目玉_ポップアップ */
  CHOMEDAMA_POPUP: 1062,

  /** 超目玉_パネル */
  CHOMEDAMA_PANEL: 1063,

  /** ランキング枠 */
  RANKING: 1064,

  /** ポイント履歴 */
  POINT_HISTORY: 1065,

  /** RPデイリサーチ */
  RP_DAY_RESEARCH: 1066,

  /** CID会員登録後リダイレクト */
  CID_REDIRECT: 1070,

  /** どこ得特別還元 */
  DOKOTOKU_SPECIAL: 1071,

  /** どこ得特別還元案件詳細ページ Twitterシェア */
  DOKOTOKU_TWITTER: 1072,

  /** どこ得特別還元案件詳細ページ LINEシェア */
  DOKOTOKU_LINE: 1073,

  /** どこ得特別還元案件詳細ページ リンクコピーシェア */
  DOKOTOKU_SHARE_LINK: 1074,

  /** ランキングピックアップ */
  RANKING_PICKUP: 1075,

  /** どこ得特別還元案件詳細シェア登録完了画面経由 */
  SIGNUP_CREATED_VIA_SPECIAL_AD_SHARE: 1076,

  /** 年末年始キャンペーンの掲載枠 */
  CAMPAIGN_MISSION: 1077,

  /** ショップ&サービス利用履歴のバナー */
  AFFILIATE_HISTORY_BANNER: 1078,

  /** ギフト獲得履歴のバナー */
  GIFT_HISTORY: 1079,

  /** アンケートラリー商材誘導枠 */
  ENQUETE_RALLY_RESULT: 1080,

  /** ポイント交換ページバナー */
  EX_SELECT: 1082,

  /** サイトジャック広告 */
  TAKEOVER_AD: 1083,

  /** LINE登録後ページの設定案件 */
  LINE_LP_AFFILIATE: 1099,

  /** LINE登録後ページのカテゴリボタン */
  LINE_LP_CATEGORY: 1100,

  /** ⚪︎人限定超高還元 */
  LIMITED_HIGH_BONUS: 1098,

  /** スタッフチョイス */
  STAFF_CHOICE: 1101,
} as const;
/**
 * PHP版の修正も合わせて行ってください。
 * sites/common/src/Ecnavi/Common/Constants/EcnFrames.php
 */
